import { Button } from "@/components/ui/button";
import { ButtonModel, FeaturePageLinkModel } from "@/lib/models/ContenfulSections";
import { ContentfulLivePreview } from "@contentful/live-preview";
import Link from "next/link";

interface ButtonComponentProps {
    className?: string | undefined;
    contenfulEntryId: string;
    contentfulFieldId: string; 
    buttonVariant?: "highlightFilled" | "lightFilled" | "darkFilled" | "highlightOutline" | "lightOutline" | "darkOutline" | "highlightText" | "lightText" | "darkText" | undefined;
    buttonSize?: "sm" | "md" | "lg" | "snug" | "icon" | undefined;
    buttonModel: ButtonModel | undefined;
}

const ButtonComponent: React.FC<ButtonComponentProps> = ({
    className = "",
    contenfulEntryId,
    contentfulFieldId,
    buttonVariant = undefined,
    buttonSize = undefined,
    buttonModel
}) => {

    if (buttonModel == undefined) {
        console.error("ButtonModel is undefined for ButtonComponent. See ButtonComponent.tsx. contenfulEntryId: ", contenfulEntryId, "fieldId: ", contentfulFieldId);
        return null
    } 
    if (buttonModel.action == undefined) {
        console.error("ButtonModel.action is undefined for ButtonComponent with title: ", buttonModel.title, ". See ButtonComponent.tsx. contenfulEntryId: ", contenfulEntryId, "fieldId: ", contentfulFieldId);
        return null
    }

    // TODO - move urls to config / contentful / environment variables
    let slug = "/";  
    let openInNewTab = false;

    switch (buttonModel.action) {
        case "Go To Home":
            slug = "/";
            break;
        case "Go To Sign Up":
            slug = "/register";
            break;
        case "Go To Sign In":
            slug = process.env.NEXT_PUBLIC_LEGACY_REDIRECT_URL ?? 'NEXT_PUBLIC_LEGACY_REDIRECT_URL'
            break;
        case "External Link":
            if (!buttonModel.externalLink) {
                return null;
            }
            // DEMO =  "https://outlook.office365.com/owa/calendar/CMNAVIGATOR1@copmer.com/bookings/s/R0R5p38oJUqA9QlQbbELYQ2"

            openInNewTab = true;
            slug = buttonModel.externalLink ?? "/";
            
            break;
        case "Go To Feature Page":
            slug = buttonModel.linkPage?.slug ?? "/";
            break;
        default:
            console.error("ButtonModel.action is not recognized for ButtonComponent with title: ", buttonModel.title, ". See ButtonComponent.tsx. contenfulEntryId: ", contenfulEntryId, "fieldId: ", contentfulFieldId);
            break;
    }

    if (openInNewTab) {
        return (
            <Link 
                target="_blank"
                rel="noopener noreferrer"

                {...ContentfulLivePreview.getProps({
                    entryId: contenfulEntryId,
                    fieldId: contentfulFieldId,
                    locale: "en-US",
                })}
                href={slug}>
                <Button
                    className={className}
                    variant={buttonVariant}
                    size={buttonSize}>
                    {buttonModel?.title}
                </Button>
            </Link>
        )
    } 
    return (
        
        <Link 

            {...ContentfulLivePreview.getProps({
                entryId: contenfulEntryId,
                fieldId: contentfulFieldId,
                locale: "en-US",
            })}
            href={slug}>
            <Button
                className={className}
                variant={buttonVariant}
                size={buttonSize}>
                {buttonModel?.title}
            </Button>
        </Link>
    )
}

export default ButtonComponent;